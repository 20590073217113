import React, { useEffect, useState } from 'react'
import { motion } from 'framer-motion';
import { useMediaQuery } from 'react-responsive';

//Images
import fpBanner from '../images/clashmarkerFPBannerAlt1.jpg'
import fpBannerSlide2 from '../images/clashmarkerFPBannerAlt2.jpg'
import fpBannerSlide3 from '../images/clashmarkerFPBannerAlt3.jpg'
import fpBannerSlide4 from '../images/clashmarkerFPBannerAlt4.jpg'
import fpBannerMobileTitle from '../images/clashMarkerFPBannerMobileTitle.jpg'
import fpBannerMobile from '../images/clashmarkerFPBannerMobile1.jpg'
import fpBannerMobile2 from '../images/clashmarkerFPBannerMobile2.jpg'
import fpBannerMobile3 from '../images/clashmarkerFPBannerMobile3.jpg'
import fpBannerMobile4 from '../images/clashmarkerFPBannerMobile4.jpg'
import fpBannerTitle from '../images/clashmarkerFPBannerTitle.jpg'

//CSS
import "../css/StoreFront.css"

function BannerCarousel() {

    const [bannerIndex, setBannerIndex] = useState(0);

    const isMobile = useMediaQuery({
        query: '(max-width: 700px)'
    })

    useEffect(()=>{
        
        const bannerInterval = setInterval(()=>{

            if(isMobile){
                switch(bannerIndex){
                    case 0:
                        document.getElementById("fpBanner0-mobile").classList.add("banner-hide");
                        break;
                    case 1:
                        document.getElementById("fpBanner1-mobile").classList.add("banner-hide");
                        break;
                    case 2:
                        document.getElementById("fpBanner2-mobile").classList.add("banner-hide");
                        break;
                    case 3:
                        document.getElementById("fpBanner3-mobile").classList.add("banner-hide");
                        break;
                    case 4:
                        document.getElementById("fpBanner0-mobile").classList.add("banner-show");
                        setTimeout(()=>{
                            document.getElementById("fpBanner1-mobile").classList.remove("banner-hide");
                            document.getElementById("fpBanner1-mobile").style.opacity = "1";
                            document.getElementById("fpBanner2-mobile").classList.remove("banner-hide");
                            document.getElementById("fpBanner2-mobile").style.opacity = "1";
                            document.getElementById("fpBanner3-mobile").classList.remove("banner-hide");
                            document.getElementById("fpBanner3-mobile").style.opacity = "1";
                            document.getElementById("fpBanner0-mobile").classList.remove("banner-show");
                            document.getElementById("fpBanner0-mobile").classList.remove("banner-hide");
                        }, 1500);
                        break;
                    default:
                        break;
                }
            }
            else{
                switch(bannerIndex){
                    case 0:
                        document.getElementById("fpBanner0-desktop").classList.add("banner-hide");
                        break;
                    case 1:
                        document.getElementById("fpBanner1-desktop").classList.add("banner-hide");
                        break;
                    case 2:
                        document.getElementById("fpBanner2-desktop").classList.add("banner-hide");
                        break;
                    case 3:
                        document.getElementById("fpBanner3-desktop").classList.add("banner-hide");
                        break;
                    case 4:
                        document.getElementById("fpBanner0-desktop").classList.add("banner-show");
                        setTimeout(()=>{
                            document.getElementById("fpBanner1-desktop").classList.remove("banner-hide");
                            document.getElementById("fpBanner1-desktop").style.opacity = "1";
                            document.getElementById("fpBanner2-desktop").classList.remove("banner-hide");
                            document.getElementById("fpBanner2-desktop").style.opacity = "1";
                            document.getElementById("fpBanner3-desktop").classList.remove("banner-hide");
                            document.getElementById("fpBanner3-desktop").style.opacity = "1";
                            document.getElementById("fpBanner0-desktop").classList.remove("banner-show");
                            document.getElementById("fpBanner0-desktop").classList.remove("banner-hide");
                        }, 1500);
                        break;
                    default:
                        break;
                }
            }

            setBannerIndex((oldIndex) => { return oldIndex === 4 ? 0 : oldIndex + 1})

        }, 5000);

        return () => {
            clearInterval(bannerInterval);
        };

    }, [bannerIndex, isMobile])

    return (
        <div id={isMobile ? "banner-container-mobile" : "banner-container-desktop"}>
            <motion.img src={isMobile ? fpBannerMobileTitle : fpBannerTitle} id={isMobile ? "fpBanner0-mobile" : "fpBanner0-desktop"} alt="Featured product Banner Slide 1"/>
            <motion.img src={isMobile ? fpBannerMobile : fpBanner} id={isMobile ? "fpBanner1-mobile" : "fpBanner1-desktop"} alt="Featured product Banner Slide 2"/>
            <motion.img src={isMobile ? fpBannerMobile2 : fpBannerSlide2} id={isMobile ? "fpBanner2-mobile" : "fpBanner2-desktop"} alt="Featured product Banner Slide 3"/>
            <motion.img src={isMobile ? fpBannerMobile3 : fpBannerSlide3} id={isMobile ? "fpBanner3-mobile" : "fpBanner3-desktop"} alt="Featured product Banner Slide 4"/>
            <motion.img src={isMobile ? fpBannerMobile4 : fpBannerSlide4} id={isMobile ? "fpBanner4-mobile" : "fpBanner4-desktop"} alt="Featured product Banner Slide 5"/>
        </div>
    )

}

export default BannerCarousel