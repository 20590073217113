import { loadStripe } from "@stripe/stripe-js";

let stripePromise = null;

const initializeStripe = async () => {
    if (!stripePromise) {
        stripePromise = await loadStripe("pk_live_51OJLD4GtSqtIeEARZzD6jcTqYBWdLtbeNBf2MkdVjo2y0OoTzIcyu5Kv7Rw0jt1WhAF8saaufisPOJl2oL6cFzJ600GGia2rDf");
    }
    return stripePromise;
};

export default initializeStripe;

