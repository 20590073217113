import React from 'react'
import { Link } from 'react-router-dom'

function SiteMap({isMobile}) {
    return (
        <footer id={isMobile ? "footer-container-mobile" : "footer-container-desktop"}>
            {!isMobile &&
                <div id={"footer-site-map-container-desktop"}>
                    <div className={"footer-site-column-desktop"}>
                        <h5>General</h5>
                        <Link to={"/"}>Home</Link>
                        <Link to={"/store"}>TPS Store</Link>
                        <Link to={"/faq"}>Product FAQs</Link>
                    </div>
                    <div className={"footer-site-column-desktop"}>
                        <h5>Product Insight</h5>
                        <Link to={"/mindful"}>Mindful</Link>
                        <Link to={"/clashmarker"} style={{cursor: 'pointer', width: '50%', marginLeft: '15%'}}>Clash Marker</Link>
                    </div>
                    <div className={"footer-site-column-desktop"}>
                        <h5>Client Websites</h5>
                        <a href="https://mindfulapp.info" target="_blank" rel="noreferrer">Mindful</a>
                        <a href="https://maddiekeyes.com" target="_blank" rel="noreferrer" style={{width: '50%', marginLeft: '15%'}}>Maddie Keyes Photography</a>
                    </div>
                    <div className={"footer-site-column-desktop"}>
                        <h5>Help</h5>
                        <Link to={"/contactus"}>Contact Us</Link>
                        <Link to={"/legal"}>Legal</Link>
                    </div>
                    <div className={"footer-site-column-address-desktop"}>
                        <h5>Mailing Address</h5>
                        <address>Transparent Software LLC<br/>13046 Racetrack Road<br/>PMB 258<br/>Tampa, FL, 33626</address>
                    </div>
                </div>
            }
            <p>&#169; Copyright 2023. Transparent Software LLC. All rights reserved.</p>
        </footer>
        
    )
}

export default SiteMap