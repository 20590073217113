import './css/App.css';
import {useRef, useEffect, useState} from 'react'
import {useMediaQuery} from 'react-responsive';
import {motion, useAnimation} from 'framer-motion';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4'

//Icons
import { HiMenu, HiOutlineTemplate, HiOutlineTerminal, HiUser } from "react-icons/hi";
import { HiDevicePhoneMobile } from "react-icons/hi2";

//Images
import initialsLogo from './images/TPSLogo+Initials.png'
import designIcon from './images/designIcon.png'
import innovateIcon from './images/innovateIcon.png'
import deliverIcon from './images/deliverIcon.png'
import mindfulLogo from './images/logoCircle.png'
import clashLogo from './images/clashMarkerLogo.png'
import tpsFullLogo from './images/TPSLogo+Name.png'

//Videos
import landingVideo from './videos/TPSLanding.mp4'
import MobileNavigationWindow from './Components/MobileNavigationWindow';
import SiteMap from './Components/SiteMap';

function App() {

  const vidRef = useRef();
  const [isLoaded, setLoading] = useState(false);
  const loadingModal = useAnimation();
  const navModalAnim = useAnimation();
  const [navModal, setNavModal] = useState(false);
  ReactGA.initialize("G-SZLZLTXLRV")

  
  const isMobile = useMediaQuery({
    query: '(max-width: 700px)'
  })

  useEffect(()=>{

    ReactGA.send({
      hitType: "pageview",
      page: "/",
      title: "Home"
    });

    let options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.65
    };

    const body = document.body;
    const previousOverflow = body.style.overflow;
    const previousPaddingRight = body.style.paddingRight;

    loadingModal.start("visible");
    navModalAnim.start("visible");

    const scrollbarWidth = window.innerWidth - document.body.offsetWidth;
    document.body.style.paddingRight = `${scrollbarWidth}px`;
    document.body.style.overflowY = "hidden";

    vidRef.current.addEventListener('loadeddata', (e)=>{
      setTimeout(()=>{
        loadingModal.start("hidden").then(()=>{
          vidRef.current.play();
          document.body.style.overflowY = "scroll";
          document.body.style.paddingRight = 0;
        });
      }, 1500);
    });

    body.style.overflow = previousOverflow;
    body.style.paddingRight = previousPaddingRight;

    function callbackFunc(entries, observerFour){
      entries.forEach(entry => {
        switch(entry.target.id){
          case "designSection":
            if(entry.isIntersecting){
              document.getElementById("designSection").classList.remove("hidden-container");
              document.getElementById("designSection").classList.add("fadeIn-anim");
              setTimeout(()=>{
                document.getElementById("innovateSection").classList.remove("hidden-container");
                document.getElementById("innovateSection").classList.add("fadeIn-anim");
              }, 250)

              setTimeout(()=>{
                document.getElementById("deliverSection").classList.remove("hidden-container");
                document.getElementById("deliverSection").classList.add("fadeIn-anim");
              }, 750)
            }
            break;
          case "card1":
            if(entry.isIntersecting){
              if(isMobile){
                document.getElementById("card1").classList.remove("hidden-container");
                document.getElementById("card1").classList.add("fadeIn-anim-left");
  
                document.getElementById("card2").classList.remove("hidden-container");
                document.getElementById("card2").classList.add("fadeIn-anim-right");
  
                document.getElementById("card3").classList.remove("hidden-container");
                document.getElementById("card3").classList.add("fadeIn-anim-left");
  
                document.getElementById("card4").classList.remove("hidden-container");
                document.getElementById("card4").classList.add("fadeIn-anim-right");
              }
              else{
                document.getElementById("card1").classList.remove("hidden-container");
                document.getElementById("card1").classList.add("fadeIn-anim-up");

                document.getElementById("card2").classList.remove("hidden-container");
                document.getElementById("card2").classList.add("fadeIn-anim-down");

                document.getElementById("card3").classList.remove("hidden-container");
                document.getElementById("card3").classList.add("fadeIn-anim-up");

                document.getElementById("card4").classList.remove("hidden-container");
                document.getElementById("card4").classList.add("fadeIn-anim-down");
              }
            }
            break;
          case "product1":
            if(entry.isIntersecting){
              document.getElementById("product1").classList.remove("hidden-container");
              document.getElementById("product1").classList.add("fadeIn-anim");

              document.getElementById("product2").classList.remove("hidden-container");
              document.getElementById("product2").classList.add("fadeIn-anim");

              document.getElementById("website1").classList.remove("hidden-container");
              document.getElementById("website1").classList.add("fadeIn-anim");

              document.getElementById("website2").classList.remove("hidden-container");
              document.getElementById("website2").classList.add("fadeIn-anim");
            }
            break;
          default:
            break;
        }
      });
    }

    let observer = new IntersectionObserver(callbackFunc, options);

    const allElements = document.querySelectorAll('.hidden-container');

    allElements.forEach((element)=>{observer.observe(element);});

  }, [loadingModal, navModalAnim, isMobile])

  function onUpdate(latest){
    if(latest.opacity === 0){
      setLoading(true);
    }
  }

  function showHideModal(){
    if(navModal){
      navModalAnim.start("hidden").then(()=>{
        setNavModal(false);
      });
    }
    else{
      setNavModal(true);
      navModalAnim.start({x: 500});
    }
  }

  const loadal = {visible: {opacity: [0,1]}, hidden: {opacity: [1, 0], transition: {duration: 1}}}
  const naval = {visible: {opacity: [0,1]}, hidden: {opacity: [1, 0], transition: {duration: 0.5}}}

  return (
    <div className="App" style={{overflowX: "hidden"}}>
      {!isLoaded && 
        <motion.div animate={loadingModal} variants={loadal} id="loadingCover" onUpdate={onUpdate}>
          <motion.img src={tpsFullLogo} id={isMobile ? "loading-cover-img-mobile" : "loading-cover-img"} animate={{opacity: [0, 1], transition: {repeat: Infinity, duration: 1.75, repeatType: "reverse"}}}/>
        </motion.div>
      }
      <header>
        {!isMobile && 
          <nav className={"nav-desktop"}>
            <img alt="Transparent Software Logo" src={initialsLogo} id={"nav-logo-desktop"}/>
            <div id={"nav-links-desktop"}>
              <motion.a whileHover={{color: ['#000000', '#508B68'], y: [0, -2]}} href="#explore"><p>Our Products</p></motion.a>
              <motion.a whileHover={{color: ['#000000', '#508B68'], y: [0, -2]}} href="#whatweoffer"><p>Services</p></motion.a>
              <motion.a whileHover={{color: ['#000000', '#508B68'], y: [0, -2]}} href="#whatwedo"><p>What We Do</p></motion.a>
              <Link to={"/contactus"}>
                <motion.a whileHover={{color: ['#000000', '#508B68'], y: [0, -2]}} href="#whatwedo"><p>Contact Us</p></motion.a>
              </Link>
              <Link to={"/store"} id="nav-contact-desktop">
                <motion.button id="nav-contact-button" whileHover={{backgroundColor: '#FFBE55'}}>
                    <p style={{color: "#F2F4F3"}}>Shop</p>
                </motion.button>
              </Link>
            </div>
          </nav>
        }
        {isMobile && 
          <nav className={"nav-mobile"}>
            <Link to={"/"} id="logo-link-container">
              <img src={initialsLogo} id={"nav-logo-mobile"} alt="Transparent Software Logo"/>
            </Link>
            <div id="nav-mobile-group-right">
              <button onClick={()=>{window.location.href = window.location.origin + "/store"}} className="mobile-shop-button">Shop</button>
              <HiMenu id={"hamburger-icon-mobile"} size={25} onClick={()=> showHideModal() }/>
            </div>
          </nav>
        }
      </header>
      <main>
        {isMobile && navModal && 
          <MobileNavigationWindow naval={naval} navModalAnim={navModalAnim}/>
        }
        <section id="landing">
          {!isMobile && 
            <div className={"landing-container"}>
              <motion.div className={"landing-text-container"} animate={{opacity: [0,1], transition: {delay: 1.9, duration: 2}}}>
                <motion.h1 animate={{x: [-150, 0], transition: {type: "tween", duration: 1.75, delay: 1.85}}}>Clarity in</motion.h1>
                <motion.h1 style={{marginTop: 0}} animate={{x: [-150, 0], transition: {type: "tween", duration: 2, delay: 1.75}}}>Every Code</motion.h1>
                <p>Transparent Software is dedicated to delivering innovative, reliable, and transparent solutions that drive success. Our approach is rooted in clear communication, cutting-edge technology, and a commitment to excellence.</p>
                <div id={"landing-text-button-container"}>
                  <motion.button whileHover={{opacity:[1, 0.75]}} style={{backgroundColor: "rgba(80, 139, 104, 0.25)"}} onClick={()=>{document.getElementById("explore").scrollIntoView({behavior: 'smooth', block: "center"});}}><p style={{color: "#508B68"}}>Shop our Products</p></motion.button>
                  <motion.button whileHover={{opacity:[1, 0.75]}} style={{backgroundColor: "#508B68"}}><p style={{color: "#F2F4F3", fontWeight: "500"}} onClick={()=>{document.getElementById("whatweoffer").scrollIntoView({behavior: 'smooth', block: "center"});}}>Explore our Services</p></motion.button>
                </div>
              </motion.div>
              <video className={"landing-video"} src={landingVideo} ref={vidRef} autoPlay muted loop playsInline webkit-playsinline/>
            </div>
          }
          {isMobile && 
            <div className={"landing-container-mobile"}>
              <video className={"landing-video-mobile"} src={landingVideo} ref={vidRef} autoPlay muted loop/>
              <motion.div className={"landing-text-container-mobile"} animate={{opacity:[0,1], transition: {delay: 2, duration: 1}}}>
                <motion.h1 animate={{y: [15, 0], transition: {delay: 2, duration: 1}}}>Clarity in Every Code</motion.h1>
                <p>We are dedicated to delivering innovative, reliable, and transparent solutions that drive success. Our approach is rooted in clear communication, cutting-edge technology, and a commitment to excellence.</p>
                <div id={"landing-text-button-container-mobile"}>
                  <button style={{backgroundColor: "rgba(80, 139, 104, 0.25)"}}><p style={{color: "#508B68"}}>Shop our Products</p></button>
                  <button style={{backgroundColor: "#508B68"}}><p style={{color: "#F2F4F3", fontWeight: "500"}}>Explore our Services</p></button>
                </div>
              </motion.div>
            </div>
          }
        </section>
        <section id="whatwedo">
          <div id={isMobile ? "wedo-container-mobile" : "wedo-container-desktop"}>
            <h2>It's What We <span style={{color: "#78A48A"}}>Do</span></h2>
            <div id={isMobile ? "wedo-sections-container-mobile" : "wedo-sections-container-desktop"}>
              
              <div id="designSection" className={isMobile ? "wedo-section-mobile hidden-container" : "wedo-section-desktop hidden-container"} style={isMobile ? {borderBottom: "#78A48A 2px solid"} : {borderRight: "#78A48A 3px solid"}}>
                <img src={designIcon} alt="Paint Pallete Clipart" className={isMobile ? "wedo-section-image-mobile" : "wedo-section-image-desktop"}/>
                <h3>Design</h3>
                <p>With a keen eye for detail and a commitment to excellence, it is our goal to ensure every aspect of the software design is not just visually appealing but also intuitively navigable, setting a solid foundation for your project's success.</p>
              </div>

              <div id="innovateSection" className={isMobile ? "wedo-section-mobile hidden-container" : "wedo-section-desktop hidden-container"}>
                <img src={innovateIcon} alt="Lightbulb Clipart" className={isMobile ? "wedo-section-image-mobile" : "wedo-section-image-desktop"}/>
                <h3>Innovate</h3>
                <p>We are dedicated to exploring new frontiers in software development, constantly pushing the boundaries of what's possible. Our approach combines the latest technological advancements with creative problem-solving strategies. </p>
              </div>

              <div id="deliverSection" className={isMobile ? "wedo-section-mobile hidden-container" : "wedo-section-desktop hidden-container"} style={isMobile ? {borderTop: "#78A48A 2px solid"} : {borderLeft: "#78A48A 3px solid"}}>
                <img src={deliverIcon} alt="Cardboard box Clipart" className={isMobile ? "wedo-section-image-mobile" : "wedo-section-image-desktop"}/>
                <h3>Deliver</h3>
                <p>Delivery is more than just meeting deadlines. It's about bringing your vision to life with exceptional quality. We employ rigorous testing and quality assurance processes to ensure the software not only meets, but exceeds your expectations. 
</p>
              </div>
            </div>
          </div>
        </section>
        <section id="whatweoffer">
          <div id={isMobile ? "weoffer-container-mobile" : "weoffer-container-desktop"}>
            <h2>What we Offer</h2>
            <div id={isMobile ? "weoffer-cards-container-mobile" : "weoffer-cards-container-desktop"}>
              
              <div id="card1" className={isMobile ? "weoffer-card-mobile hidden-container" : "weoffer-card-desktop hidden-container"} style={{transform: isMobile ? "translateX(4%)" : "translateY(4%)"}}>
                <HiOutlineTemplate size={isMobile ? 50 : 60} style={{color: "#508B68", marginBottom: "-5%"}}/>
                <h4>Web Development</h4>
                <p>Transform your online presence with our bespoke Web Development services. At Transparent Software, we specialize in creating responsive, fast, and engaging websites. Tailored to your brand's unique needs, our web solutions are designed for optimal user experience, ensuring your digital footprint stands out. </p>
              </div>

              <div id="card2" className={isMobile ? "weoffer-card-mobile hidden-container" : "weoffer-card-desktop hidden-container"} style={{transform: isMobile ? "translateX(-4%)" : "translateY(-4%)"}}>
                <HiDevicePhoneMobile size={isMobile ? 50 : 60} style={{color: "#508B68", marginBottom: "-5%"}}/>
                <h4>Mobile App Development</h4>
                <p>Bring your ideas to life with our Mobile App Development service. Our team at Transparent Software crafts user-centric, innovative mobile applications for both iOS and Android platforms. Focused on performance and usability, our apps offer seamless experiences, connecting you effectively with your mobile audience. </p>
              </div>

              <div id="card3" className={isMobile ? "weoffer-card-mobile hidden-container" : "weoffer-card-desktop hidden-container"} style={{transform: isMobile ? "translateX(4%)" : "translateY(4%)"}}>
                <HiOutlineTerminal  size={isMobile ? 50 : 60} style={{color: "#508B68", marginBottom: "-5%"}}/>
                <h4>SaaS Solutions</h4>
                <p>Elevate your business with our custom SaaS Solutions. Transparent Software delivers scalable, secure, and efficient cloud-based applications. Our SaaS expertise ranges from enterprise-level systems to niche tools, all designed to boost productivity, enhance collaboration, and drive growth, while ensuring data security and compliance. </p>
              </div>

              <div id="card4" className={isMobile ? "weoffer-card-mobile hidden-container" : "weoffer-card-desktop hidden-container"} style={{transform: isMobile ? "translateX(-4%)" : "translateY(-4%)"}}>
                <HiUser size={isMobile ? 50 : 60} style={{color: "#508B68", marginBottom: "-5%"}}/>
                <h4>Flexibility</h4>
                <p>At Transparent Software, flexibility is our forte. We understand unique visions require unique solutions. If your needs extend beyond our core services, our team is ready to listen, adapt, and collaborate. Together, we can explore innovative avenues to turn your specific software aspirations into tangible realities. </p>
              </div>

            </div>
          </div>
        </section>
        <section id="explore">
          <div id={isMobile ? "explr-container-mobile" : "explr-container-desktop"}>
            <h2>Explore</h2>
            <div id={isMobile ? "prdct-container-mobile" : "prdct-container-desktop"}>
              <h3>Products</h3>
              <div id="prdctitems" className={isMobile ? "prdct-items-container-mobile" : "prdct-items-container-desktop"}>

                <div id="product1" className={isMobile ? "prdct-item-mobile hidden-container" : "prdct-item-desktop hidden-container"}>
                  <div className={isMobile ? "item-top-mobile" : "item-top-desktop"}>
                    {<img src={mindfulLogo} alt="Mindful Logo"/>}
                    {/*<HiDevicePhoneMobile size={isMobile ? 50 : 85} style={{color: "#508B68"}}/>*/}
                    <h4>Mindful Mobile App</h4>
                  </div>
                  <div className={isMobile ? "item-bottom-mobile" : "item-bottom-desktop"}>
                    <p>A mobile app designed to help you day plan with your mental well-being in mind. Creating schedules that leave you relaxed and rested.</p>
                    <div className={isMobile ? "item-button-bar-mobile" : "item-button-bar-desktop"}>
                      <Link to={"/mindful"} className={isMobile ? 'item-button-bar-mobile-p' : 'item-button-bar-desktop-p'}><motion.p whileHover={{opacity: [1, 0.75], y: [0, 1]}}>Read more</motion.p></Link>
                      <motion.button whileHover={{opacity:[1, 0.75]}} onClick={()=>{window.open('https://apps.apple.com/us/app/mindful/id1637254506', '_blank')}}><p>Download</p></motion.button>
                    </div>
                  </div>
                </div>

                <div id="product2" className={isMobile ? "prdct-item-mobile hidden-container" : "prdct-item-desktop hidden-container"}>
                  <div className={isMobile ? "item-top-mobile" : "item-top-desktop"}>
                    {<img src={clashLogo} alt="Clash Marker Logo"/>}
                    <h4>Clash Marker</h4>
                  </div>
                  <div className={isMobile ? "item-bottom-mobile" : "item-bottom-desktop"}>
                    <p>Clash Marker is a tool for marking your clashes found in Navisworks on your 2D CAD files and 3D BIM models.</p>
                    <div className={isMobile ? "item-button-bar-mobile" : "item-button-bar-desktop"}>
                      <Link to={"/clashmarker"} className={isMobile ? 'item-button-bar-mobile-p' : 'item-button-bar-desktop-p'}><motion.p style={{opacity: 1, cursor: "pointer"}} whileHover={{opacity: [1, 0.65], y: [0, 1]}}>Read more</motion.p></Link>
                      <motion.button disabled={false} whileHover={{opacity:[1, 0.75]}} style={{opacity: 1}} onClick={()=>{window.location.href = `${window.location.origin}/store?product=clashmarker`}}><p>Purchase</p></motion.button>
                    </div>
                  </div>
                </div>

              </div>
            </div>

            <div id={isMobile ? "web-container-mobile" : "web-container-desktop"}>
              <h3>Websites</h3>
              <div className={isMobile ? "web-items-container-mobile" : "web-items-container-desktop"}>

                <div id="website1" className={isMobile ? "web-item-mobile hidden-container" : "web-item-desktop hidden-container"}>
                  <div className={isMobile ? "webitem-top-mobile" : "webitem-top-desktop"}>
                    {<img src={new URL("https://maddiekeyes.netlify.app/linkPreview.png")} alt="Mindful Logo"/>}
                  </div>
                  <div className={isMobile ? "webitem-bottom-mobile" : "webitem-bottom-desktop"}>
                    <h4>Maddie Keyes Photography</h4>
                    <p>Beautifully elegant web design to reflect gorgeous photography from Maddie Keyes.</p>
                    <div className={isMobile ? "webitem-button-bar-mobile" : "webitem-button-bar-desktop"}>
                      <motion.button whileHover={{opacity:[1, 0.75]}} onClick={()=>{window.open('http://maddiekeyes.netlify.app','_blank')}}><p>Visit</p></motion.button>
                    </div>
                  </div>
                </div>

                <div id="website2" className={isMobile ? "web-item-mobile hidden-container" : "web-item-desktop hidden-container"}>
                  <div className={isMobile ? "webitem-top-mobile" : "webitem-top-desktop"}>
                    {<img src={new URL("https://mindfulapp.info/linkPreview.png")} alt="Mindful Logo"/>}
                  </div>
                  <div className={isMobile ? "webitem-bottom-mobile" : "webitem-bottom-desktop"}>
                    <h4>Mindful Info Site</h4>
                    <p>Sleek, minimal design meets functionality to represent the info site of Mindful.</p>
                    <div className={isMobile ? "webitem-button-bar-mobile" : "webitem-button-bar-desktop"}>
                      <motion.button whileHover={{opacity:[1, 0.75]}} onClick={()=>{window.open('http://mindfulapp.info','_blank')}}><p>Visit</p></motion.button>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </section>
      </main>
      <SiteMap isMobile={isMobile}/>
    </div>
  );
}

export default App;
