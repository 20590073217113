import React, { useState, useEffect } from 'react';
import '../css/FreeTrial.css';
import {useMediaQuery} from 'react-responsive';
import { Link, useLocation } from 'react-router-dom';
import { motion, useAnimation } from 'framer-motion';
import ReactGA from 'react-ga4'
import ReCAPTCHA from 'react-google-recaptcha'

import {firestorage} from '../firebase/firebaseClient.js'
import { getDownloadURL, ref} from 'firebase/storage'

import logo from "../images/clashMarkerLogo+BG.png"
import initialsLogo from '../images/TPSLogo+Initials.png'
import { HiMenu } from 'react-icons/hi';
import axios from 'axios';
import SiteMap from '../Components/SiteMap.jsx';
import MobileNavigationWindow from '../Components/MobileNavigationWindow.jsx';

function FreeTrial() {
    const [email, setEmail] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [requestError, setRequestError] = useState(false);
    const [downloadLink, setDownloadLink] = useState(false);
    const [captchaVerified, setCaptchaVerified] = useState(false);
    const [navModal, setNavModal] = useState(false);
    const [downloadUrl, setDownloadUrl] = useState(null);
    const navModalAnim = useAnimation();
    const location = useLocation();
    ReactGA.initialize("G-SZLZLTXLRV");

    const isMobile = useMediaQuery({
        query: '(max-width: 700px)'
    })

    useEffect(()=>{

        const params = new URLSearchParams(location.search)
        navModalAnim.start("visible");
        
        ReactGA.send({
            hitType: "pageview",
            page: "/clashmarkerfreetrial",
            title: "Clash Marker Free Trial Page"
        });

        const fetchDownloadUrl = async () => {
            try {
                const filePath = 'ClashMarker_Windows_1.1.0_installer.exe';
                const fileRef = ref(firestorage, filePath);
                
                const url = await getDownloadURL(fileRef);
                setDownloadUrl(url);

                if(downloadUrl){
                    const link = document.createElement('a');
                    link.href = downloadUrl;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }

            } catch (error) {
                console.error("Error fetching download URL:", error);
            }
        };

        if(params.get("download") === "true"){
            setDownloadLink(true);
            fetchDownloadUrl()
        }

        
    }, [location.search, navModalAnim, downloadUrl])

    const naval = {visible: {opacity: [0,1]}, hidden: {opacity: [1, 0], transition: {duration: 0.5}}}

    const handleSubmit = async (e) => {
        e.preventDefault();

        await axios.post("https://freetrialrequest-cg3iutb5oq-uc.a.run.app", {email: email}).then((res)=>{

            if(res.data.msg !== "SUCCESS"){
                setRequestError(true);
            }
            
            setSubmitted(true);

        })
        
        
    };

    const handleCaptchaChange = (value) => {
        setCaptchaVerified(!!value);
    }

    function showHideModal(){
        if(navModal){
            navModalAnim.start("hidden").then(()=>{
                setNavModal(false);
            });
        }
        else{
            setNavModal(true);
            navModalAnim.start({x: 500});
        }
    }

    return (
        <div style={{flex: 1, height: '100vh'}}>
            <header>
                {!isMobile && 
                    <nav className={"nav-desktop"}>
                        <Link to={"/"} id="nav-logo-desktop">
                            <img alt="Transparent Software Logo" src={initialsLogo}/>
                        </Link>
                        <div id={"nav-links-desktop"}>
                            <motion.a whileHover={{color: ['#000000', '#508B68'], y: [0, -2]}} href="/#explore"><p>Our Products</p></motion.a>
                            <motion.a whileHover={{color: ['#000000', '#508B68'], y: [0, -2]}} href="/#whatweoffer"><p>Services</p></motion.a>
                            <motion.a whileHover={{color: ['#000000', '#508B68'], y: [0, -2]}} href="/#whatwedo"><p>What We Do</p></motion.a>
                            <motion.a whileHover={{color: ['#000000', '#508B68'], y: [0, -2]}} href="/contactus"><p>Contact Us</p></motion.a>
                            <Link to={"/store"} id="nav-contact-desktop">
                                <motion.button id="nav-contact-button" whileHover={{backgroundColor: '#FFBE55'}}>
                                    <p style={{color: "#F2F4F3"}}>Shop</p>
                                </motion.button>
                            </Link>
                        </div>
                    </nav>
                } 
                {isMobile && 
                    <nav className={"nav-mobile"}>
                        <Link to={"/"} id="logo-link-container">
                            <img src={initialsLogo} alt="Transparent Software Logo" id="nav-logo-mobile"/>
                        </Link>
                        <HiMenu id={"hamburger-icon-mobile"} size={25} onClick={()=> showHideModal()}/>
                    </nav>
                }
            </header>
            {isMobile && navModal && 
                <MobileNavigationWindow naval={naval} navModalAnim={navModalAnim}/>
            }
            <main className="container">
                {!downloadLink ? (
                    <motion.div animate={{y:[20, 0], opacity: [0, 1], transition:{duration: 1,}}} className="content-box">
                        <div className={isMobile ? "header-mobile" : "header-desktop"}>
                            <img
                                src={logo}
                                alt="Clash Marker Logo"
                                className="logo"
                            />
                            {!submitted && <div className={isMobile ? "header-text-mobile" : "header-text-desktop"}>
                                <h2>Request a Free Trial of Clash Marker!</h2>
                                <p>Check out <strong>ALL</strong> of Clash Markers features in an unlimited free trial and feel the power it brings to your clash resolution workflow.</p>
                                <p>Want to read more about Clash Marker? Visit the <Link to={"/clashmarker"}>Clash Marker Product Page</Link></p>
                            </div>}
                        </div>
                        {!submitted ? (
                            <form onSubmit={handleSubmit} className={isMobile ? "form-mobile" : "form"}>
                                
                                <p>Upon entering your email, you will be provisioned a free trial license key that will last only <strong>2 weeks FROM THE DATE OF REQUEST.</strong></p>
                                <input
                                    type="email"
                                    placeholder="Enter your email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className="email-input"
                                    required
                                />
                                <ReCAPTCHA id="recaptcha" size="normal" sitekey="6LeDkH0qAAAAACn_0ZS9NgFoq0lrAyzjntEi15Nq" onChange={handleCaptchaChange}/>
                                <button type="submit" className="submit-button" style={{opacity: !captchaVerified ? 0.25 : 1}} disabled={!captchaVerified}>Request</button>
                            </form>
                        ) : (
                            <div className={requestError ? "success-message error" : "success-message"}>
                                <h2>{requestError ? 'Request Submittal Failed!' :'Request Submitted Successfully!'}</h2>
                                {!requestError && <p>Thank you for requesting a free trial of Clash Marker! You will receive an email from <strong>contact@tps-technology.com</strong> with your license key and download link.</p>}
                                {requestError &&  <p>You have already signed up for the Clash Marker free trial. If your trial ran out and you would like to continue using Clash Marker you can purchase it <a href="https://tps-technology.com/store?product=clashmarker">here.</a></p>}
                            </div>
                        )}
                    </motion.div>
                ) : (
                    <motion.div animate={{y:[20, 0], opacity: [0, 1], transition:{duration: 1,}}} className="content-box">
                        <div className={isMobile ? "header-mobile" : "header-desktop"}>
                            <img
                                src={logo}
                                alt="Clash Marker Logo"
                                className="logo"
                            />
                            <div className={isMobile ? "header-text-mobile" : "header-text-desktop"}>
                                <h2>Clash Marker Free Trial<br/> Download Link</h2>
                                <p>Congratulations on receiving your license key!<br/><br/>If you download did not start automatically, click <a href={downloadUrl}>here</a> to start your free trial download.</p>
                            </div>
                        </div>
                    </motion.div>
                )}
            </main>
            <SiteMap isMobile={isMobile}/>
        </div>
    );
}

export default FreeTrial;
