import React, { useState } from 'react';
import { motion } from 'framer-motion';

//Icon
import { HiDuplicate } from "react-icons/hi";
import { HiCheck } from "react-icons/hi";

function CopyKeyButton({licenseKey, error, disabled}) {

    const [copyingKey, setCopyingKey] = useState(false);

    function copyKey(key) {
        setCopyingKey(true);
        try{
            navigator.clipboard.writeText(key).catch((err) => {
                error(true, key);
                setCopyingKey(false);
                return;
            });
        }
        catch(err){
            error(true, key);
            setCopyingKey(false);
            return;
        }

        setTimeout(()=>{setCopyingKey(false);}, 2500);
    }


    return (
        <motion.button key={licenseKey} disabled={!disabled} onClick={() => { copyKey(licenseKey); }} whileHover={{ opacity: !disabled ? [0.25,0.25] : [1, 0.65] }} style={{opacity: !disabled ? 0.25 : 1, cursor: !disabled ? 'not-allowed' : 'pointer'}}>
            {!copyingKey && <div style={{ display: 'flex', paddingBlock: '2%' }}>Copy <HiDuplicate style={{ paddingLeft: '5%', transform: "translateY(5%)" }} /></div>}
            {copyingKey && <div style={{ display: 'flex', paddingBlock: '2%' }}>Copied <HiCheck style={{ paddingLeft: '5%', transform: "translateY(5%)" }} /></div>}
        </motion.button>
    )
}

export default CopyKeyButton