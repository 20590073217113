import { initializeApp } from 'firebase/app';
//import { getAnalytics } from 'firebase/analytics';
import { getAuth } from 'firebase/auth';
import * as firebaseAuth from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import * as firebaseStorage from "firebase/storage"

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: process.env.REACT_APP_TPS_PUBLIC_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_TPS_PUBLIC_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_TPS_PUBLIC_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_TPS_PUBLIC_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_TPS_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_TPS_PUBLIC_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_TPS_PUBLIC_FIREBASE_MEASUREMENT_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const database = getFirestore(app);
const firestorage = firebaseStorage.getStorage(app)
//const analytics = getAnalytics(app);

export {auth, firebaseAuth, database, firestorage};