import React, {useEffect} from 'react'
import ReactGA from 'react-ga4'
import { useLocation, useNavigate } from 'react-router-dom';

export default function CMRedirect() {
    ReactGA.initialize("G-SZLZLTXLRV");
    const location = useLocation();
    const navigate = useNavigate()

    useEffect(()=>{

        const params = new URLSearchParams(location.search);

        const redirectEvent = params.get("src");

        switch(redirectEvent){
            case "reddit":
                ReactGA.send({
                    hitType: "pageview",
                    page: "/cmredirect",
                    title: "Reddit AD"
                });
                navigate("/clashmarker");    
                break;
            case "plumbingforums":
                ReactGA.send({
                    hitType: "pageview",
                    page: "/cmredirect",
                    title: "Plumbing Forum AD"
                });
                navigate("/clashmarker");
                break;
            case "facebook":
                ReactGA.send({
                    hitType: "pageview",
                    page: "/cmredirect",
                    title: "Facebook AD"
                });
                navigate("/clashmarker");
                break;
            case "linkedin":
                ReactGA.send({
                    hitType: "pageview",
                    page: "/cmredirect",
                    title: "LinkedIn AD"
                });
                navigate("/clashmarker");
                break;
            case "linkedin-trial":
                ReactGA.send({
                    hitType: "pageview",
                    page: "/cmredirect",
                    title: "LinkedIn AD"
                });
                navigate("/clashmarkerfreetrial");
                break;
            case "youtube":
                ReactGA.send({
                    hitType: "pageview",
                    page: "/cmredirect",
                    title: "YouTube AD"
                });
                navigate("/clashmarker");
                break;
            case "afsa":
                ReactGA.send({
                    hitType: "pageview",
                    page: "/cmredirect",
                    title: "AFSA AD"
                });
                navigate("/clashmarkerfreetrial");
                break;
            case "afsa-read":
                    ReactGA.send({
                        hitType: "pageview",
                        page: "/cmredirect",
                        title: "AFSA AD"
                    });
                    navigate("/clashmarker");
                    break;
            default:
                break;
        }

    }, [location, navigate])

    return (
        <div></div>
    )
}
