import { database } from "../firebase/firebaseClient";
import { setDoc, doc, onSnapshot } from 'firebase/firestore';
import getStripe from "./initializeStripe"

export async function createCheckoutSession(successRedirect, cancelRedirect, uid, amt, orderID, purchasedProduct, subType, price) {
    const userDoc = doc(database, "users", `${uid}`);

    await setDoc(
        doc(database, userDoc.path, "checkout_sessions", orderID),
        { price: subType.includes("yearly") ? "price_1Q56WAGtSqtIeEARmLBi1TuY" : "price_1Q56WAGtSqtIeEARhBpFNiqF", success_url: successRedirect + `?orderID=${orderID}&mt=${amt}`, cancel_url: cancelRedirect, quantity: amt }
    );

    await setDoc(
        doc(database, userDoc.path, "user_orders", orderID),
        {assignedUser: uid, price: subType.includes("yearly") ? "price_1Q56WAGtSqtIeEARmLBi1TuY" : "price_1Q56WAGtSqtIeEARhBpFNiqF", quantity: amt, product: purchasedProduct, subType: subType, total: price, date: new Date().toLocaleDateString(), keysGend: false, confirmationEmailSent: false}
    );

    // Redirect to checkout

    onSnapshot(doc(database, userDoc.path, "checkout_sessions", orderID), async (snap) => {
        const { sessionId } = snap.data();

        if (sessionId) {
            const stripe = await getStripe();
            stripe.redirectToCheckout({ sessionId });
        }
    })
}