import React, { useEffect, useState } from 'react'
import { useMediaQuery } from 'react-responsive';

//CSS
import "../css/Checkout.css"

//Images
import constellationItemsIcon from "../images/constellationItemsIcon.jpg"

function ProductCheckoutItem({name, quantity, subscription}) {

    const isMobile = useMediaQuery({
        query: '(max-width: 700px)'
    })

    const [totalAmtForProduct, setTotalAmtForProduct] = useState(0);

    useEffect(()=>{
        switch (subscription) {
            case "$599.99 / year":              
                setTotalAmtForProduct(parseFloat(599.99 * parseFloat(quantity)));
                break;
            case "$64.99 / month":
                setTotalAmtForProduct(parseFloat(64.99 * parseFloat(quantity)));
                break;
            default:
                break;
        }
    }, [subscription, quantity])

    return (
        <div id={isMobile ? "product-container-mobile" : "product-container-desktop"}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: "center", width: '70%'}}>
                <img src={constellationItemsIcon} id={isMobile ? "product-icon-mobile" : "product-icon-desktop"} alt={`${name} logo preview`}/>
                <div>
                    <h5>{name}</h5>
                    <p>{subscription} / license</p>
                </div>
            </div>
            <p style={{width: '30%', textAlign: 'center'}}> x {quantity} licenses</p>
            <p style={{width: '40%', textAlign: 'right', fontSize: isMobile ? '4.15vw' : '0.75vw'}}>${totalAmtForProduct}</p>
        </div>
    )

}

export default ProductCheckoutItem;