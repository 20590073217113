import React from 'react'
import {useState} from 'react'
import { motion } from 'framer-motion'
import { useMediaQuery } from 'react-responsive'
import { Link } from 'react-router-dom'

//CSS
import '../css/ItemPurchaseWindow.css'

//Image
import closeIcon from '../images/closeIcon.png'

import constellaationPurchaseHeaderImage from '../images/constellationPurchaseHeader.jpg'

function ClashMarkerPurchaseWindow({closeWindow, checkout}) {

    const [productAmount, setProductAmount] = useState(1);
    const [subType, setSubType] = useState("$599.99 / year");

    const isMobile = useMediaQuery({
        query: '(max-width: 700px)'
    })

    function updateProductAmount(e){
        if(e.target.value === '0'){
            setProductAmount(1);
        }
        else{
            setProductAmount(e.target.value);
        }
    }

    function increaseProductAmt(){
        setProductAmount(oldAmount => oldAmount + 1);
    }

    function decreaseProductAmt(){
        if(productAmount === 1) return;
        setProductAmount(oldAmount => oldAmount - 1);
    }

    function saveToCart(){
        if(productAmount === '0' || productAmount.toString().length === 0){
            setProductAmount(1);
            console.log('setting product amount to 1');
            return;
        }
        else{
            localStorage.removeItem("tps-cart");
            localStorage.setItem("tps-cart", JSON.stringify({productName: "Clash Marker", amt: productAmount, subType: subType}));
            window.location.href = window.location.href + "/checkout";
        }
    }

    return (
        <div id="purchase-window-main">
            <motion.div animate={{opacity:[0,1], y:[15, 0], transition: {duration: 0.75,}}} id={isMobile ? "item-purchase-container-mobile" : "item-purchase-container-desktop"}>
                <motion.img whileHover={{opacity: [1, 0.5]}} onClick={closeWindow} id={isMobile ? "close-icon-mobile" : "close-icon-desktop" } src={closeIcon}/>
                <div id={isMobile ? "header-container-mobile" : "header-container-desktop"}>
                    <img src={constellaationPurchaseHeaderImage} alt="Navy Blue to Cyan Gradient with Constellation Logo in the Center"/>
                    <div id={isMobile ? "header-choice-container-mobile" : "header-choice-container-desktop"}>
                        <h1>Clash Marker</h1>
                        <p>Automated clash translation from 3D Navisworks files to 2D CAD files and 3D BIM models. Windows only.</p>
                        <select class={isMobile ? "select-style-mobile" : "select-style"} onChange={(e)=>{setSubType(e.target.value);}}>
                            <option value="$599.99 / year">$599.99 <span style={{color: 'red'}}>/ year</span></option>
                            <option value="$64.99 / month">$64.99 <span style={{color: 'red'}}>/ month</span></option>
                        </select>
                        <div id={isMobile ? "quantity-add-container-mobile" : "quantity-add-container-desktop"} style={{position: 'relative', zIndex: 5}}>
                            <div id={isMobile ? "quantity-container-mobile" : "quantity-container-desktop"}>
                                <button style={{all: 'unset'}}onClick={decreaseProductAmt}><p>-</p></button>
                                <input placeholder='1' defaultValue={productAmount} value={productAmount} onChange={(e)=>{updateProductAmount(e);}}/>
                                <button style={{all: 'unset'}}onClick={increaseProductAmt}><p>+</p></button>
                                <p style={{marginLeft: '5%', fontSize: isMobile ? "4vw" : '1.5vw'}}>license{productAmount > 1 ? "s" : ""}</p>
                            </div>
                            <Link style={{flex: 1, maxWidth: '25%', textDecoration: "none", minHeight: '5%'}}>
                                <motion.button whileHover={{opacity: [1, 0.75]}} onClick={saveToCart} id={isMobile ? "add-button-mobile" : "add-button-desktop"}>
                                    <p>Purchase</p>
                                </motion.button>
                            </Link>
                        </div>
                    </div>
                </div>
                <div id={isMobile ? "details-container-mobile" : "details-container-desktop"}>
                    <section id="try-clashmarker" style={{marginTop: '3%'}}>
                        <div id={isMobile ? "purchase-clashmarker-container-mobile" : "purchase-clashmarker-container"} className={isMobile ? "section-container-mobile" : "section-container-desktop"} style={{display: 'flex', flexDirection: 'row', flex: 1, justifyContent: 'space-between', alignItems: 'center'}}>
                            <h3 style={{fontSize: isMobile ? '5vw' :'1vw'}}>Get a 2 week free trial of Clash Marker now!</h3>
                            <div style={{flexDirection: 'row', display: 'flex', width: '60%', justifyContent: 'flex-end'}}>
                                <motion.button whileHover={{opacity: [1, 0.65]}} onClick={()=>{window.location.href = window.location.href.replace("/clashmarker", "/clashmarkerfreetrial");}}><p style={{fontSize: isMobile ? '4vw' : undefined}}>Try Clash Marker</p></motion.button>
                            </div>
                        </div>
                    </section>
                    <section id="overview">
                        <h3>Overview</h3>
                        <p>Clash Marker is an innovative software tool designed to revolutionize the clash resolution experience between Autodesk Navisworks and AutoCAD, BricsCAD, SprinkCAD, HydraCAD, and Revit. This tool revolutionizes the way architects, engineers, and designers handle clash resolution in their projects, ensuring precision and efficiency in a fraction of the time.</p>
                    </section>
                    <section id="whatsincluded">
                        <h3>What's Included</h3>
                        <p>Your subscription comes with:</p>
                        <ul>
                            <li><strong>The Clash Marker software</strong></li>
                            <li><strong>The Clash Marker Navisworks Plugin (crucial for marking viewpoints in your drawings or models)</strong></li>
                            <li><strong>The Clash Marker Revit Plugin</strong></li>
                        </ul>
                    </section>
                    <section id="keyFeatures">
                        <h3>Key Features</h3>
                        <ol>
                            <li>Seamless Integration between Navisworks and AutoCAD, BricsCAD, HydraCAD, SprinkCAD, and Revit</li>
                                <ul>
                                    <li>Clash Marker allows users to effortlessly translate complex 3D clash reports from Navisworks into the 2D environments of AutoCAD, BricsCAD, HydraCAD, and SprinkCAD, as well as the 3D environments of Revit.</li>
                                </ul>
                            <li>Comprehensive Clash Report Translation</li>
                                <ul>
                                    <li>Conduct thorough clash tests in Navisworks, assessing potential collisions between various installations (HVAC, Fire, Structural components, etc.) in a 3D model.</li>
                                    <li>Efficiently process these reports using Clash Marker to identify and address design conflicts on your own drawings or models.</li>
                                </ul>
                            <li>Comprehensive Viewpoint Report Translation</li>
                                <ul>
                                    <li>Using viewpoint reports from Navisworks, Clash Marker is able to mark the location and orientation of viewpoints directly onto your drawing or model.</li>
                                </ul>
                            <li>Effortless Marking on 2D Drawings</li>
                                <ul>
                                    <li>Users can import multiple clash reports into Clash Marker.</li>
                                    <li>The tool marks each identified clash directly onto your drawing, making it easy to locate and resolve potential issues.</li>
                                </ul>
                            <li>Effortless Marking on 3D Models</li>
                                <ul>
                                    <li>Users can import multiple clash reports into Clash Marker.</li>
                                    <li>The tool marks each identified clash directly onto your 3D model, making it easy to locate and resolve potential issues.</li>
                                </ul>
                            <li>Advance Compatability</li>
                                <ul>
                                    <li>Clash Marker is equipped to handle Navisworks files with custom origin points and transforms, ensuring accuracy even when working with models based off non-standard coordinates.</li>
                                </ul>
                            <li>User-Friendly Interface</li>
                                <ul>
                                    <li>A streamlined, intuitive interface allows for easy navigation and use, making it accessible for professionals at all skill levels.</li>
                                </ul>
                        </ol>
                    </section>
                    <section id="benefits">
                        <h3>Benefits</h3>
                        <ul>
                            <li><strong>Enhanced Accuracy: </strong>Minimize errors and rework by precisely identifying and resolving clashes in the early stages of design.</li>
                            <li><strong>Time and Cost Efficiency: </strong>Reduce the time spent on manual translation of clash reports, speeding up the design process and cutting down costs.</li>
                            <li><strong>Improved Collaboration: </strong>Facilitate clearer communication and collaboration among team members by providing easy-to-understand 2D representations of clashes.</li>
                        </ul>
                    </section>
                    <section id="idealfor">
                        <h3>Ideal for</h3>
                        <ul>
                            <li>Architects, Engineers, and Designers seeking efficient solutions for clash resolution in their software of choice.</li>
                            <li>Professionals in construction and building design requiring accurate and quick resolution of potential design conflicts.</li>
                        </ul>
                    </section>
                    <section id="reelemin">
                        <h4>Get Clash Marker today and transform the way you handle design clashes, ensuring a smoother, more efficient design process.</h4>
                    </section>
                    <section id="moreInfo" style={{paddingBottom: isMobile ? "5%" : undefined}}>
                        <p>Want to hear more about what went into making Clash Marker? Check out our info page on Clash Marker <Link style={{color: '#508B68'}} to={"/clashmarker"}>here</Link>.</p>
                        <p>Have questions about Clash Marker? Check out our FAQs <Link style={{color: '#508B68'}} to={"/clashmarker"}>here</Link>, or <Link style={{color: '#508B68'}} to={"/contactus"}>contact us</Link>.</p>
                    </section>
                </div>
            </motion.div>
        </div>
    )
}

export default ClashMarkerPurchaseWindow